import * as React from "react"
import Navbar from "./components/Navbar"
import Footer from './components/Footer'
import "./scss/_global.scss"
import "./scss/contact.scss"

export default function contactPage () {
  return (
	<main>
		<title>virtual WestJet | Contact</title>
		<Navbar></Navbar>
		<div className="contact">
			<div className="head">
				<h1>Contact Us</h1>
				<a href="https://discord.gg/eGVaMx8dNb"><img className="socials" src={'/svg/discord.svg'} alt="discord"/></a>
				<a href="mailto:webmaster@vwestjet.org"><img className="socials" src={'/svg/email.svg'} alt="email"/></a><br />
                <img src={'/images/home_image_1.png'} className="contact-image" alt="window of westjet plane"></img>
				<p className="image-credit">Screenshot courtesy of Joey McCormick</p>
			</div>
			<div className="contact-content">
				<p></p>
			</div>
		</div>
		<Footer></Footer>
	</main>
  )
}
